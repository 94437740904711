/*
  Awesome Datatable jQuery Plugin v0.2.0
  This plugin enables the TBS project to easily add datatables for index actions.
  To use this plugin you must stick to a certain pattern:
  The controller action should implement 2 response formats (js and html). The html response must only return a instance
  variable for the ransack search object (e.g. @q = User.search). The js response must return the "old" and complete
  ransack response.
  An example implementation if found at the index action of 'app/controllers/management/smtp_servers_controller.rb'.
 */

(function () {
    var AwesomeDatatableHelper;

    AwesomeDatatableHelper = (function () {
        function AwesomeDatatableHelper() {
            this.back_links = $("[data-behavior='datatable-back-link']");
            if (this.back_links.length !== 0) {
                this.setup();
            }
        }

        AwesomeDatatableHelper.prototype.setup = function () {
            return this.back_links.each(function (index, element) {
                var $link, localStorageKeyValue, paramsObject, url;
                $link = $(element);
                localStorageKeyValue = $link.data("datatable-back-link-for");
                if (typeof Storage !== 'undefined') {
                    url = $link.attr("href");
                    paramsObject = JSON.parse(localStorage.getItem(localStorageKeyValue));
                    if (paramsObject) {
                        return $link.attr("href", url + ("?" + ($.param(paramsObject))));
                    }
                }
            });
        };

        return AwesomeDatatableHelper;

    })();

    jQuery(function () {
        return new AwesomeDatatableHelper;
    });

    (function ($, window) {
        var AwesomeDatatable, pluginName;
        pluginName = "awesome_datatable";
        AwesomeDatatable = (function () {
            var decodeParamsToJSONObject, setLocalStorageAndURL;

            AwesomeDatatable.prototype.defaults = {
                debug: false,
                spinner: "<tr><td colspan='42'><div class='text-center py3'><i class='fas fa-spinner fa-spin fa-2x'></i></div></td></tr>",
                filterFormSelector: '#filter_form',
                perPageEnabled: false,
                perPageName: 'per_page',
                perPageDefault: 10,
                localStorageKeyValue: null,
                autoFillEnabled: true
            };

            function AwesomeDatatable(element, options) {
                this.options = $.extend({}, this.defaults, options);
                this.$filterForms = $("" + this.options.filterFormSelector);
                this.$this = $(element);
                this.init();
                return;
            }

            AwesomeDatatable.prototype.log = function (message) {
                if (this.options.debug) {
                    return typeof console !== "undefined" && console !== null ? console.log(message) : void 0;
                }
            };

            AwesomeDatatable.prototype.init = function () {
                this.log("Load index data on '" + (this.$this.attr('id')) + "'.");
                this.addPerPageField();
                this.initialFilterFormSubmit();
                $.ajaxSetup({
                    cache: false
                });
                this.registerSortLinkEventHandler();
                this.registerPaginationEventHandler();
                this.registerFormEventhandler();
                return this.registerPerPageSelectEventHandler();
            };

            AwesomeDatatable.prototype.addPerPageField = function () {
                if (!this.options.perPageEnabled) {
                    return;
                }
                return this.$filterForms.append("<input type='hidden' name='" + this.options.perPageName + "' value='" + this.options.perPageDefault + "'/>");
            };

            AwesomeDatatable.prototype.registerPerPageSelectEventHandler = function () {
                var $perPageInput;
                if (!this.options.perPageEnabled) {
                    return;
                }
                $perPageInput = this.$filterForms.find("input[name='" + this.options.perPageName + "']");
                return this.$this.on('click', 'div.dropdown.table_per_page li a', (function (_this) {
                    return function (event) {
                        $perPageInput.val($(event.target).data('per-page'));
                        $(event.target).closest('.dropdown').dropdown('toggle');
                        setLocalStorageAndURL(_this, $(event.target).attr("href").split("?")[1]);
                        return _this.showLoader();
                    };
                })(this));
            };

            AwesomeDatatable.prototype.registerPaginationEventHandler = function () {
                this.log('Register pagination event handlers called.');
                this.$this.on('click', 'table:first-child + div .pagination:not(.disable_datatable_pagination) a', (function (_this) {
                    return function (event) {
                        event.stopPropagation();
                        _this.showLoader();
                        if (_this.options.autoFillEnabled) {
                            setLocalStorageAndURL(_this, $(event.target).attr('onclick').split('\'')[1].split('?')[1]);
                        }
                    };
                })(this));
            };

            AwesomeDatatable.prototype.registerSortLinkEventHandler = function () {
                this.log("Register sort link event hanelder called.");
                this.$this.on("click", "thead .sort_link", (function (_this) {
                    return function () {
                        _this.showLoader();
                        if (_this.options.autoFillEnabled) {
                            setLocalStorageAndURL(_this, $(event.target).attr("href").split("?")[1]);
                        }
                    };
                })(this));
            };

            AwesomeDatatable.prototype.registerFormEventhandler = function () {
                this.log("Register form event handlers called.");
                this.$filterForms.find(':input').not('[data-no-datatable-event]').not('.bootstrap-select .dropdown-menu .form-control').not('.async-pick .ap__popup .form-control').each((function (_this) {
                    return function (index, element) {
                        var $input, stoppedTyping;
                        $input = $(element);
                        if ($input.attr('type') === 'search') {
                            stoppedTyping = null;
                            $input.on('keyup', function (event) {
                                if (event.keyCode < 45 && [8, 13].indexOf(event.keyCode) < 0) {
                                    event.preventDefault();
                                    return false;
                                }
                                if (stoppedTyping) {
                                    clearTimeout(stoppedTyping);
                                }
                                return stoppedTyping = setTimeout(function () {
                                    return _this.submitFilterForm();
                                }, 500);
                            });
                        } else if (element.classList.contains('datepicker')) {
                            $input.on('changeDate', function (event) {
                                return _this.submitFilterForm();
                            });
                        } else {
                            $input.on('change', function (event) {
                                return _this.submitFilterForm();
                            });
                        }
                    };
                })(this));
            };

            AwesomeDatatable.prototype.submitFilterForm = function () {
                this.log("Submit filter form called.");
                this.showLoader();
                if (this.options.autoFillEnabled) {
                    setLocalStorageAndURL(this, this.$filterForms.serialize());
                }
                $.ajax({
                    url: this.$filterForms.attr('action'),
                    type: this.$filterForms.attr('method'),
                    dataType: 'script',
                    data: this.$filterForms.serialize()
                });
            };

            AwesomeDatatable.prototype.initialFilterFormSubmit = function () {
                var $form_element, additional_search_params_hash, name, search_params, search_params_hash, value;
                this.log("Initial submit filter form called.");
                this.showLoader();
                search_params = window.location.search;
                additional_search_params_hash = {};
                if (this.options.autoFillEnabled) {
                    if (search_params !== '' && typeof search_params !== 'undefined') {
                        search_params_hash = decodeParamsToJSONObject(search_params);
                        if (this.options.perPageEnabled && typeof search_params_hash[this.options.perPageName] !== 'undefined') {
                            this.$filterForms.find("[name='" + this.options.perPageName + "']").val(search_params_hash[this.options.perPageName]);
                        }
                        for (name in search_params_hash) {
                            value = search_params_hash[name];
                            $form_element = this.$filterForms.find("[name='" + name + "'][type!='hidden']");
                            if ($form_element.length !== 0) {
                                this.log("Initial submit: Filter form element from search query found: " + name + ": " + value);
                                $form_element.each((function (_this) {
                                    return function (index, element) {
                                        element = $(element);
                                        if (element.attr("type") === "checkbox") {
                                            if (Array.isArray(value)) {
                                                if (value.includes(element.val())) {
                                                    return element.attr("checked", true);
                                                } else {
                                                    return element.attr("checked", false);
                                                }
                                            } else {
                                                if (element.val() === value) {
                                                    return element.attr("checked", true);
                                                } else {
                                                    return element.attr("checked", false);
                                                }
                                            }
                                        } else if (element.attr("type") === "radio") {
                                            if (element.val() === value) {
                                                $form_element.find("input[type=radio][name='" + name + "']").attr("checked", false);
                                                return element.attr("checked", true);
                                            }
                                        } else {
                                            return element.val(value);
                                        }
                                    };
                                })(this));
                            } else {
                                this.log("Initial submit: Filter form element from search query not found: " + name + ": " + value);
                                additional_search_params_hash[name] = value;
                            }
                        }
                    } else {
                        this.removeLocalStorageKey();
                    }
                }
                $.ajax({
                    url: this.$filterForms.attr('action'),
                    type: this.$filterForms.attr('method'),
                    dataType: 'script',
                    data: this.$filterForms.serialize() + ("&" + ($.param(additional_search_params_hash)))
                });
            };

            AwesomeDatatable.prototype.showLoader = function () {
                this.log("Show loader called.");
                if (this.$this.find('tbody').length !== 0) {
                    this.$this.find('tbody').html(this.options.spinner);
                } else {
                    this.$this.html(this.options.spinner);
                }
            };

            decodeParamsToJSONObject = function (param_string) {
                return decodeURIComponent(param_string.replace(/\+/g, " ")).replace(/(^\?)/, "").split("&").map((function (key_value_data) {
                    var key, value;
                    key_value_data = key_value_data.split("=");
                    key = key_value_data[0];
                    value = key_value_data[1];
                    if ($.isArray(this[key])) {
                        this[key].push(value);
                    } else if (this[key]) {
                        this[key] = [this[key], value];
                    } else {
                        this[key] = value;
                    }
                    return this;
                }).bind({}))[0];
            };

            setLocalStorageAndURL = function (at, param_string) {
                var error, object, query, regex, url;
                object = {};
                if (typeof Storage !== 'undefined' && at.options.localStorageKeyValue) {
                    query = at.options.localStorageKeyValue.split("_")[0].replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
                    regex = new RegExp("^" + query);
                    try {
                        Object.keys(localStorage).forEach(function (key) {
                            if (key.match(regex)) {
                                localStorage.removeItem(key);
                            }
                        });
                        object = decodeParamsToJSONObject(param_string);
                        localStorage.setItem(at.options.localStorageKeyValue, JSON.stringify(object));
                    } catch (_error) {
                        error = _error;
                        if (error.name === 'NS_ERROR_FILE_CORRUPTED') {
                            this.log('Corrupted browser localStorage detected. Trying to clear localStorage');
                            localStorage.clear();
                            localStorage.setItem(at.options.localStorageKeyValue, JSON.stringify(object));
                        }
                    }
                }
                if (window.history.replaceState) {
                    url = window.location.protocol ? (window.location.protocol + "//" + window.location.hostname + ":" + window.location.port + window.location.pathname) + ("?" + ($.param(object))) : "" + window.location.protocol;
                    window.history.replaceState(void 0, void 0, url);
                }
            };

            AwesomeDatatable.prototype.removeLocalStorageKey = function () {
                var error;
                try {
                    localStorage.removeItem(this.options.localStorageKeyValue);
                } catch (_error) {
                    error = _error;
                    if (error.name === 'NS_ERROR_FILE_CORRUPTED') {
                        this.log('Corrupted browser localStorage detected. Trying to clear localStorage');
                        localStorage.clear();
                    }
                }
            };

            return AwesomeDatatable;

        })();
        return $.fn[pluginName] = function (options) {
            return this.each(function () {
                if (!$.data(this, "plugin_" + pluginName)) {
                    $.data(this, "plugin_" + pluginName, new AwesomeDatatable(this, options));
                }
                if (typeof options === 'string') {
                    return $.data(this, "plugin_" + pluginName)[options].apply($.data(this, "plugin_" + pluginName));
                }
            });
        };
    })(window.jQuery, window);

}).call(this);